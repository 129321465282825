import './App.css';
import 'firebase/firestore';
import 'firebase/auth';
import { initializeApp } from "firebase/app";
import { getAuth, updateProfile, createUserWithEmailAndPassword, signInWithEmailAndPassword, signOut, updatePassword, verifyBeforeUpdateEmail, deleteUser, sendEmailVerification, sendPasswordResetEmail } from "firebase/auth";
import { onSnapshot, getFirestore, query, collection, orderBy, addDoc, serverTimestamp, where, limit } from "firebase/firestore";
import { useEffect, useState, useCallback } from 'react';
import { Unity, useUnityContext } from "react-unity-webgl";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import FaqPage from './Pages/Faq';
import TermPage from './Pages/Termos';

const app = initializeApp({
  apiKey: "AIzaSyBYoA6ztVQNHvooWroA5zSt4yp7lt7T9eE",
  authDomain: "e-teatro-wedo.firebaseapp.com",
  databaseURL: "https://e-teatro-wedo-default-rtdb.firebaseio.com",
  projectId: "e-teatro-wedo",
  storageBucket: "e-teatro-wedo.appspot.com",
  messagingSenderId: "924379709795",
  appId: "1:924379709795:web:6e075eb1c2d19015e01356",
  measurementId: "G-HD898WJW4S"
})

const Plays = ({sendMessage, user, db}) => {

  useEffect(() => {
    sendMessage("FirebaseWebGL", "onPlayClear", JSON.stringify({data: ""}))
    const queue = query(collection(db, "plays"), where('enabled', '==', true));
    const unsubscribe = onSnapshot(queue, snapshot => {
      snapshot.docChanges().forEach((change) => {
        if (change.type === "added") {
          sendMessage("FirebaseWebGL", "onPlayUpdate", JSON.stringify({data: change.doc.data()}))
        }
      })
    })
    
    return () => {
      unsubscribe();
    }
  }, [user, db, sendMessage])

  return <></>
}

const Tickets = ({sendMessage, user, db}) => {
  useEffect(() => {
    if(!user) return;
    const queue = query(collection(db, "ticket"), where('userId', '==', user.uid));
    const unsubscribe = onSnapshot(queue, snapshot => {
      snapshot.docChanges().forEach((change) => {
        if (change.type === "added") {
          sendMessage("FirebaseWebGL", "onTicketAdded", JSON.stringify({data: change.doc.data()}))
        }else if (change.type === "modified") {
          sendMessage("FirebaseWebGL", "onTicketUpdate", JSON.stringify({data: change.doc.data()}))
        }
      })
    })
    
    return () => {
      unsubscribe();
    }
  }, [user, db, sendMessage])

  return <></>
}

const VoiceChatInvite = ({sendMessage, user, db}) => {
  const time = new Date(Date.now());

  useEffect(() => {
    if(!user) return;

    const invites = query(collection(db, "invites"), where('invited', "==", user.displayName));
    const unsubscribe = onSnapshot(invites, snapshot => {
      snapshot.docChanges().forEach((change) => {
        if (change.type === "added") {
          console.log(JSON.stringify(change.doc.data()))
        }
      })
    })

    
    return () => {
      unsubscribe();
    }
  }, [user?.displayName, db, sendMessage, time, user])
  return <></>;
}

const Chat = ({sendMessage, user, db}) => {
  const time = new Date(Date.now());
  console.log(user);

  useEffect(() => {
    if(!user) return;
    
    const queue = query(collection(db, "chat"), where('created_at', ">=", time), orderBy("created_at"), limit(15));
    const unsubscribe = onSnapshot(queue, snapshot => {
      snapshot.docChanges().forEach((change) => {
        if (change.type === "added") {
          sendMessage("FirebaseWebGL", "onChatUpdate", JSON.stringify(change.doc.data()))
        }
      })
    })

    const SendChatMessage = async (message) => {
      if(!user) return;
  
      await addDoc(collection(db, "chat"), {
        id: user.uid,
        text: message,
        displayName: user?.displayName,
        created_at: serverTimestamp()
      })
    }

    window.sendChatMessage = SendChatMessage;
    
    return () => {
      unsubscribe();
    }
  }, [user?.displayName, db, sendMessage, time, user])

  return <>
  </>
}

function App() {
  const db = getFirestore(app);
  const auth = getAuth(app);
  const [ready, setReady] = useState(false);
  const [currentUser, setCurrentUser] = useState(auth.currentUser);

  useEffect(() => {
    setCurrentUser(auth.currentUser);
  }, [auth.currentUser])

  const {
    unityProvider,
    isLoaded,
    loadingProgression,
    sendMessage,
    addEventListener,
    removeEventListener,
    requestFullscreen,
    takeScreenshot,
    unload,
  } = useUnityContext({
    loaderUrl: "/assets/Build.loader.js",
    dataUrl: "/assets/Build.data.unityweb",
    frameworkUrl: "/assets/Build.framework.js.unityweb",
    codeUrl: "/assets/Build.wasm.unityweb",
    webglContextAttributes: {
      preserveDrawingBuffer: true,
    },
  });

  window.register = (name, email, password) => register(name, email, password);
  window.signIn = (email, password) => signIn(email, password);
  window.logout = () => logout();
  window.verifyUser = (pwd) => onVerifyUser(pwd);
  window.updateEmail = (newEmail) => updateEmail(newEmail);
  window.changePassword = (newPassword) => changePassword(newPassword);
  window.forgotPassword = (emailToSend) => forgotPassword(emailToSend);
  window.deleteAccount = () => deleteAccount();
  window.onUnityReady = () => setReady(true);

  const onVerifyUser = useCallback((pwd) => {
    signInWithEmailAndPassword(auth, currentUser?.email, pwd).then(() => {
      sendMessage("FirebaseWebGL", "onUserVerified");
    })
  }, [auth, currentUser?.email, sendMessage])

  const changePassword = useCallback((newPassword) => {
    updatePassword(currentUser, newPassword).then(() => {
      sendMessage("FirebaseWebGL", "onPasswordChanged");
    })
  }, [currentUser, sendMessage])

  const updateEmail = useCallback((newEmail) => {
    verifyBeforeUpdateEmail(currentUser, newEmail).then(() => {
      sendMessage("FirebaseWebGL", "onEmailChanged");
    })
  }, [currentUser, sendMessage])

  const deleteAccount = useCallback(() => {
    deleteUser(currentUser).then(() => {
      sendMessage("FirebaseWebGL", "onUserDeleted");
    })
  }, [currentUser, sendMessage])

  const forgotPassword = useCallback((emailToSend) => {
    sendPasswordResetEmail(auth, emailToSend).then(() => {
      sendMessage("FirebaseWebGL", "onForgotPassword");
    });
  }, [auth, sendMessage])

  function onRegisterWebGL(receivedUser) {
    sendMessage("FirebaseWebGL", "onRegisterWebGL", JSON.stringify({id: receivedUser.uid, email: receivedUser.email, displayName: receivedUser?.displayName}));
  }

  function onLoginWebGL(receivedUser) {
    console.log("Get user");
    sendMessage("FirebaseWebGL", "onLoginWebGL", JSON.stringify({id: receivedUser.uid, email: receivedUser.email, displayName: receivedUser?.displayName}));
  }

  const register = (name, email, password) => {
    createUserWithEmailAndPassword(auth, email, password)
    .then((response) => {
      console.log(response);
      onRegisterWebGL(response.user);
      sendEmailVerification(response.user);
      updateProfile(response.user, {
        displayName: name,
      })
    })
    .catch((error) => {
      sendMessage("FirebaseWebGL", "onNotification", error.message);
    });
  } 

  const signIn = (email, password) => {
    signInWithEmailAndPassword(auth, email, password)
    .then((response) => {
      if(!response.user.emailVerified && response.user.email !== "wedo@teste.com"){
        sendMessage("FirebaseWebGL", "onLoginFailedUserNotVerified");
        setCurrentUser(response.user);
        return;
      } 

      setCurrentUser(response.user);
      onLoginWebGL(response.user);
    })
    .catch((error) => {
      sendMessage("FirebaseWebGL", "onNotification", error.message);
    });
  }

  const logout = () => {
    setCurrentUser(null);
    signOut(auth);
  }

  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/" exact element={
            <header className="App-header">
              <div className="App-Content" style={{width: '100vw', height: '100vh', display: 'flex', alignItems:'center', justifyContent: 'center'}}>
                {!isLoaded && <p>Carregando {Math.floor(100 * loadingProgression)}%</p>}
                <Unity unityProvider={unityProvider} style={{ width: 1280, height: 720 }}/>
                {ready &&
                  <div>
                    {currentUser && <Chat sendMessage={sendMessage} user={currentUser} db={db}/>}
                    {currentUser && <Tickets sendMessage={sendMessage} user={currentUser} db={db} />}
                    {currentUser && <VoiceChatInvite sendMessage={sendMessage} user={currentUser} db={db} />}
                    <Plays sendMessage={sendMessage} user={currentUser} db={db} />
                  </div>
                }
              </div>
            </header>
          }>
          </Route>
          <Route path="/faq" element={<FaqPage/>}>
          </Route>
          <Route path="/termos" element={<TermPage/>}>
          </Route>
        </Routes>
      </Router>
    </div>
  );
}

export default App;
